import { render, staticRenderFns } from "./TheCategoryTemplate.vue?vue&type=template&id=52250719&scoped=true&"
import script from "./TheCategoryTemplate.vue?vue&type=script&lang=js&"
export * from "./TheCategoryTemplate.vue?vue&type=script&lang=js&"
import style0 from "./TheCategoryTemplate.vue?vue&type=style&index=0&id=52250719&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52250719",
  null
  
)

export default component.exports